import MainTemplate from 'components/templates/MainTemplate';
import { Helmet } from 'react-helmet-async';
import styled from 'styled-components';
import { heading, metaDescription, metaKeywords, title } from 'constants/meta';
import Breadcrumbs from 'components/molecules/Breadcrumbs';
import Table from 'components/atoms/Table/Table';
import Thr from 'components/atoms/Table/Thr';
import Tr from 'components/atoms/Table/Tr';
import Td from 'components/atoms/Table/Td';
import Link from 'components/atoms/Link';
import { Ol, Li } from 'components/atoms/List';
import representatives from './representatives';

const Title = styled.h1`
  font-weight: normal;
`;

const StyledTable = styled(Table)`
  margin-top: 2em;
  margin-bottom: 2em;
`;

const RepresentativesPage = () => (
  <MainTemplate>
    <Helmet>
      <title>{title.representatives}</title>
      <meta name="keywords" content={metaKeywords.representatives} />
      <meta name="description" content={metaDescription.representatives} />
      <script
        type="text/javascript"
        async=""
        src="https://api-maps.yandex.ru/services/constructor/1.0/js/?sid=BuKxZ5_LyaSN7uiNoUyvD5A4zyukKYc0&amp;width=100%25&amp;height=400&amp;lang=ru_RU&amp;sourceType=constructor&amp;scroll=true&amp;id=representatives-map"
      />
    </Helmet>
    <Breadcrumbs
      links={[
        {
          link: '/contacts',
          text: 'Контакты',
        },
      ]}
    />
    <Title>{heading.representatives}</Title>
    <div id="representatives-map" />
    <StyledTable>
      <Thr>
        <Td>Регион</Td>
        <Td>Представитель</Td>
      </Thr>
      {Array.from(representatives.keys()).map((key) => {
        const representative = representatives.get(key);
        return (
          <Tr key={key}>
            <Td>
              <Link to={`/contacts/predstaviteli-v-regionah/${key}`}>{representative.city}</Link>
            </Td>
            <Td>
              {representative.region ? `${representative.region}, ` : ''}
              г. {representative.city}, {representative.address}, {representative.phone},{' '}
              {representative.person}
            </Td>
          </Tr>
        );
      })}
    </StyledTable>
    <p>
      Для удобства наших Клиентов с августа 2016 года компания «Золотая Камея» расширяет сеть
      региональных представительств. Мы ищем внештатных региональных представителей на условиях
      предоставления максимально возможной скидки на наш товар. Развитие по принципу - один регион,
      один представитель.
    </p>
    <h3>Обязанности представителя</h3>
    <Ol>
      <Li> Помощь Покупателю в подборе оборудования, опок и камней к ним</Li>
      <Li> Консультационные услуги по всему ассортименту наших товаров</Li>
      <Li>
        {' '}
        Решение проблемных вопросов на местах (приём/возврат товара с осмотром и составлением акта и
        др.)
      </Li>
      <Li> Выдача заказов в Вашем населённом пункте</Li>
    </Ol>
    <h3>Требования к представителю</h3>
    <Ol>
      <Li>Опыт работы в ювелирной сфере, понимание и знание технологических процессов.</Li>
      <Li>
        Наличие ювелирной мастерской или иного помещения, реальный адрес куда могут прийти клиенты
        (арендуемое помещение или в собственности)
      </Li>
    </Ol>
    <h3>Необходимая информация на рассмотрение</h3>
    <Ol>
      <Li> Адрес ювелирной мастерской (арендуемое помещение или в собственности), с индексом </Li>
      <Li> Название мастерской, если есть</Li>
      <Li> Форма собственности: частное лицо, ИП, ООО</Li>
      <Li> Телефоны, желательно с городским номером</Li>
      <Li> Сайт, если есть</Li>
      <Li> Режим работы</Li>
      <Li> Контактное лицо (ФИО)</Li>
    </Ol>
    <p>
      Желающие стать представителем и получить максимально возможную скидку на закупаемый товар,
      могут связаться с нами по телефону 8-800-700-17-89 или по почте info@gold-kamea.ru.
    </p>
  </MainTemplate>
);

export default RepresentativesPage;
